import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private $auth: AuthenticationService
  ) {}

  canActivate(): Promise<boolean> {
    return this.checkAuth();
  }

  canActivateChild(): Promise<boolean> {
    return this.checkAuth();
  }

  private async checkAuth(): Promise<boolean> {
    const isSignedIn = await this.$auth.isSignedIn();
    if (!isSignedIn) {
      await this.$auth.logout();
      this.router.navigate(['/', 'session', 'signin']);
    }

    return isSignedIn;
  }
}
