import { NgModule } from '@angular/core';

import { DatabaseModule, IRootConfig } from '@paella-front/ngx-database';

const config: IRootConfig = {
  databases: {
    app: {
      config: { name: 'dashboard', storeName: '_dashboard' },
      isDefault: true,
    },
  },
};

@NgModule({
  exports: [DatabaseModule],
  imports: [DatabaseModule.forRoot(config)],
})
class AppDatabaseModule {}

export { AppDatabaseModule };
