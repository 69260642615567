import { NgClass } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ApiService } from '@core/services/api.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { environment } from '@env';
import { IAccount } from '@models/IAccount';
import { IUser } from '@models/IUser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@UntilDestroy()
@Component({
  selector: 'pae-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  standalone: true,
  imports: [NgClass, SidebarComponent, NavbarComponent, RouterOutlet, FooterComponent],
})
export class AdminLayoutComponent implements OnInit {
  sidebarToggle = true;
  environment = environment;

  constructor(
    private $auth: AuthenticationService,
    private $api: ApiService,
    private router: Router,
    private $spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.$auth
      .getMe()
      .pipe(
        untilDestroyed(this),
        tap((account: IAccount) => {
          if (!account) {
            this.logout();
          }
        }),
        switchMap(() => this.$api.getMe()),
        tap((user: IUser) => {
          if (!user) {
            this.logout();
          }
        }),
        this.$auth.$serverError.catchErrorAndOpenModal(true),
        catchError((error: string) => {
          this.logout();
          return of(error);
        })
      )
      .subscribe();
  }

  onSidebarToggle(): void {
    this.sidebarToggle = !this.sidebarToggle;
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (this.sidebarToggle) {
      mainPanel.style.width = 'calc(100% - 260px)';
    } else {
      mainPanel.style.width = 'calc(100% - 85px)';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth < 992) {
      const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
      mainPanel.style.width = '';
      this.sidebarToggle = true;
    }
  }

  private logout(): void {
    this.$auth.logout().then(() => {
      this.$spinner.hide();
      this.router.navigate(['/', 'session']);
    });
  }
}
