import { NgModule } from '@angular/core';

import { IRootConfig, SuperHttpClientModule } from '@paella-front/ngx-super-http-client';

import { environment } from 'environments/environment';

const config: IRootConfig = {
  domains: {
    empresa: {
      url: environment.server.empresa,
      isDefault: true,
    },
    cnae: {
      url: environment.server.cnae,
      isDefault: true,
    },
    ond: {
      url: environment.server.ond,
    },
    contabilidad: {
      url: environment.server.contabilidad,
    },
    maquinaria: {
      url: environment.server.maquinaria,
    },
    oauth: {
      url: environment.server.oauth,
      headers: { Authorization: environment.PUBLIC_JWT },
    },
    export: {
      url: environment.server.export,
    },
    pgc: {
      url: environment.server.pgc,
    },
  },
};

@NgModule({
  exports: [SuperHttpClientModule],
  imports: [SuperHttpClientModule.forRoot(config)],
})
class AppHttpClientModule {}

export { AppHttpClientModule };
