import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { DOMAIN_COMPANY } from '@app/data/constants/domains';
import { SuperHttpClientProvider } from '@paella-front/ngx-super-http-client';

import { BehaviorSubject, from, iif, Observable, of, throwError } from 'rxjs';

import { catchError, filter, flatMap, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

const noop = new Observable<void>((observer) => {
  observer.next();
  observer.complete();
});

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private inProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private $auth: AuthenticationService,
    private $shttp: SuperHttpClientProvider
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((reason: HttpErrorResponse) => {
        if (reason instanceof HttpErrorResponse) {
          /// We don't want to refresh token for some requests (signin, signup, ...)
          if (!request.url.includes('/refresh')) {
            if (reason.status === 401) {
              if (this.inProgress$.getValue()) {
                /// If inProgress$ is true we will wait until is false
                return this.inProgress$.pipe(
                  filter((_inProgress$: boolean) => !_inProgress$),
                  take(1),
                  this.refreshToken(request, next)
                );
              }

              this.inProgress$.next(true);

              /// Call $auth.refreshToken()
              return from(this.$auth.refreshToken()).pipe(
                this.refreshToken(request, next),
                catchError((error) =>
                  from(this.$auth.logout()).pipe(
                    tap(() => {
                      this.inProgress$.next(false);
                      this.router.navigate(['/', 'login']);
                    }),
                    flatMap(() => throwError(error))
                  )
                )
              );
            }
          } else {
            this.$auth.logout(true);
          }
        }

        return throwError(reason);
      })
    );
  }

  private refreshToken<T>(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): (source: Observable<T>) => Observable<HttpEvent<T>> {
    return (source: Observable<T>) =>
      source.pipe(
        flatMap(() =>
          from(this.$auth.getToken()).pipe(
            // If token is null this means that user is not logged in and we return the original request
            flatMap((token) => iif(() => !!token, noop, of(request)))
          )
        ),
        tap(() => this.inProgress$.next(false)),
        switchMap(() =>
          next.handle(
            request.clone({
              setHeaders: this.$shttp.use(DOMAIN_COMPANY).headers.getAll(),
            })
          )
        )
      );
  }
}
