<footer class="footer">
  <div class="container-fluid d-sm-flex justify-content-between">
    <nav>
      <ul>
        <li>
          <a href="https://ingedop.com/" target="_blank"> {{ footerTextLink }} </a>
        </li>
        <li>
          <a href="https://ingedop.com/index.php/contacto/" target="_blank">
            {{ 'FOOTER.CONTACT' | translate }}
          </a>
        </li>
        <li>
          <a href="https://ingedop.com/index.php/politica-de-privacidad/" target="_blank">
            {{ 'FOOTER.PRIVACY_POLICY' | translate }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright">
      &copy; {{ test | date: 'yyyy' }}, made with <i class="fa fa-heart heart"></i> by
      <a href="https://paellasoft.com/" target="_blank">Paellasoft</a>
      <label class="ms-2">v{{ version }}</label>
    </div>
  </div>
</footer>
