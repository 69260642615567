import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '@env';
import { NgxSpinnerComponent } from 'ngx-spinner';
import { I18nService } from './core/services/i18n.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgxSpinnerComponent, RouterOutlet],
})
export class AppComponent {
  readonly spinnerColor = '#FFC000';

  constructor(private i18nService: I18nService) {
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
  }
}
