/* src/app/layouts/admin-layout/components/sidebar/sidebar.component.scss */
li {
  padding-right: 1px;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #66615b;
  border-radius: 10px;
}
.list-group-item {
  padding-left: 25px;
  font-size: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
}
.list-group-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-dark {
  background-color: #212120 !important;
}
.logo:after {
  display: none !important;
}
.accordion {
  padding-right: 12px;
}
.accordion-item {
  border: 0;
  background: #212120 !important;
}
.accordion-button {
  background: #212120 !important;
  color: #ffc000 !important;
  border: 0;
  border-radius: 0;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ffc000' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
}
.accordion-collapse {
  margin-top: -10px;
}
.accordion-body {
  background: #212120 !important;
  padding: 0;
}
.accordion-body a {
  margin: 10px 15px 0;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 8px;
  line-height: 30px;
  opacity: 0.6;
}
.accordion-body .active {
  opacity: 1;
}
.accordion-body .active i {
  opacity: 1;
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
  border: 0;
}
/*# sourceMappingURL=sidebar.component.css.map */
