@use 'paper-dashboard/_variables.scss';

li {
  padding-right: 1px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: variables.$default-color;
  border-radius: 10px;
}

.list-group-item {
  padding-left: 25px;
  font-size: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
}

.list-group-item:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-dark {
  background-color: variables.$dark-color !important;
}

.logo {
  padding-bottom: 0;
}

.logo:after {
  display: none !important;
}

.accordion {
  padding-right: 12px;
}

.accordion-item {
  border: 0;
  background: variables.$dark-color !important;
}

.accordion-button {
  background: variables.$dark-color !important;
  color: variables.$primary-color !important;
  border: 0;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ffc000' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
}

.accordion-collapse {
  margin-top: -10px;
}

.accordion-body {
  background: variables.$dark-color !important;
  padding: 0;
  a {
    margin: 10px 15px 0;
    color: #ffffff;
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 8px;
    line-height: 30px;
    opacity: 0.6;
  }
  .active {
    opacity: 1;
    i {
      opacity: 1;
    }
  }
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
  border: 0;
}

.logo-image-small {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  .img-logo {
    max-width: 170px;
  }

}
