<div class="wrapper">
  @if (!environment.machineryGUI) {
    <div
      class="sidebar"
      [ngClass]="{ 'sidebar-collapse': !sidebarToggle }"
      data-color="black"
      data-active-color="primary">
      <pae-sidebar-cmp [sidebarToggle]="sidebarToggle"></pae-sidebar-cmp>
    </div>
  }
  <div class="main-panel" [ngClass]="{ 'w-100': environment.machineryGUI }">
    <pae-navbar-cmp (sidebarToggleEvent)="onSidebarToggle()"></pae-navbar-cmp>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <pae-footer-cmp></pae-footer-cmp>
  </div>
</div>
