/* src/app/layouts/admin-layout/components/navbar/navbar.component.scss */
#navbarDropdownMenuLink {
  padding: 0.5rem 0;
}
.dark-color {
  background-color: #212120 !important;
}
.custom-icon {
  color: #ffc000;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
}
.btn-toggle {
  background-color: transparent !important;
  border: 1px solid #66665b;
  color: #66615b;
  font-size: 1rem;
  opacity: 0.6;
}
.btn-toggle:hover,
.btn-toggle:active,
.btn-toggle:focus {
  color: #66615b !important;
}
.img_logo {
  width: 60px;
}
.profile {
  border-radius: 50%;
  width: 80px;
  margin-left: 15px;
  margin-right: 15px;
  border: 5px solid #ffc000;
}
.navbar-nav .dropdown-menu {
  min-width: 300px;
}
.navbar-nav .dropdown-menu label {
  font-size: 14px;
  color: #66615b;
  max-width: 260px;
}
.navbar .input-group {
  width: 250px;
}
.dropdowns-container-style {
  display: flex;
}
@media (max-width: 991px) {
  .btn-toggle {
    display: none;
  }
}
/*# sourceMappingURL=navbar.component.css.map */
