<div class="modal-header">
  <h4 class="modal-title">{{ 'NAVBAR.PROFILE' | translate }}</h4>
  <i type="button" class="fa fa-close" aria-label="Close" (click)="closeModal()"> </i>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label>{{ 'TABLES.IMAGE' | translate }}: </label>
        @if (imageSrc) {
          <div class="overlay">
            <button class="deleteImg-button" (click)="deleteImage()">
              <i class="fa-solid fa-trash"></i>
            </button>
            <img class="mt-2" [src]="imageSrc" alt="Company Image" />
          </div>
        }
        @if (!imageSrc) {
          <file-upload [control]="filesControl">
            <ng-template let-i="index" let-file="file" let-control="control" #item>
              <button class="deleteImg-button" (click)="deletePreviewImage()">
                <i class="fa-solid fa-trash"></i>
              </button>
              <img class="mt-2" [src]="uploadedFile | async" alt="Company Image" />
            </ng-template>
          </file-upload>
        }
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="update text-center">
      <button class="btn btn-primary" [disabled]="loading || !file" (click)="submit()">
        @if (loading) {
          <div class="spinner-border spinner-border-sm me-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        }
        {{ 'TABLES.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
