import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from '@env';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'pae-footer-cmp',
  templateUrl: 'footer.component.html',
  standalone: true,
  imports: [DatePipe, TranslateModule],
})
export class FooterComponent {
  test: Date = new Date();
  footerTextLink: string = environment.footerTextLink;
  version: string = environment.version;
}
