/* src/app/layouts/admin-layout/admin-layout.component.scss */
:host ::ng-deep .card-content {
  padding: 15px 15px 10px;
}
:host ::ng-deep .ngx-btn-group ol,
:host ::ng-deep .ngx-btn-group ul {
  margin: 0.8rem 0 0.8rem 0.8rem;
  padding: 0;
}
:host ::ng-deep .ngx-btn-group ol ol,
:host ::ng-deep .ngx-btn-group ol ul,
:host ::ng-deep .ngx-btn-group ul ol,
:host ::ng-deep .ngx-btn-group ul ul {
  margin: 0.8rem 0 0.8rem 0.8rem;
}
:host ::ng-deep .ngx-btn-group ol li,
:host ::ng-deep .ngx-btn-group ul li {
  margin-top: 0.4rem;
}
:host ::ng-deep .ngx-btn-group dl dt {
  font-weight: 700;
}
:host ::ng-deep .ngx-btn-group dl dd {
  margin: 0.4rem 0 0.8rem 0;
}
:host ::ng-deep .sidebar-collapse .sidebar-wrapper {
  width: 85px;
}
:host ::ng-deep .sidebar-collapse .sidebar-wrapper p {
  display: none;
}
:host ::ng-deep .sidebar-collapse .sidebar-wrapper .list-group-item {
  display: none;
}
:host ::ng-deep .sidebar-collapse .sidebar-wrapper li {
  margin-bottom: 2rem;
}
:host ::ng-deep .sidebar-collapse .sidebar-wrapper li a {
  padding-top: 0;
  padding-bottom: 10px;
}
.sidebar-collapse {
  width: 85px;
  transform: translate3d(0px, 0, 0);
}
/*# sourceMappingURL=admin-layout.component.css.map */
