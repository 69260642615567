import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';

import { FullCalendarModule } from '@fullcalendar/angular';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';

import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AppDatabaseModule } from './modules/app-database.module';
import { AppHttpClientModule } from './modules/app-http-client.module';

import { DateAdapter } from './utils/date-adapter';
import { DateParserFormatter } from './utils/date-parser-formatter';

@NgModule({
  imports: [
    HttpClientModule,
    NgxSpinnerModule,
    NgbModule,
    FullCalendarModule,
    ToastrModule.forRoot(),
    AppDatabaseModule,
    AppHttpClientModule,
  ],
  exports: [NgxSpinnerModule],
  providers: [
    ConfirmationDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
