import { Injectable } from '@angular/core';

import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { format, parse } from 'date-fns';

@Injectable()
export class DateAdapter extends NgbDateAdapter<string> {
  readonly FORMAT = `yyyy-MM-dd'T'HH:mm:ss`;

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = parse(value, this.FORMAT, new Date());
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    }

    return null;
  }

  toModel(value: NgbDateStruct | null): string | null {
    if (value) {
      let month = value.month.toString();
      if (value.month.toString().length === 1) {
        month = `0${value.month}`;
      }
      const date = parse(`${value.year}${month}${value.day}`, 'yyyyMMd', new Date());
      return format(date, this.FORMAT);
    }

    return null;
  }
}
