import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { ROLE } from '@app/data/enums/role';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env';
import { NgbAccordionModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { filter, tap } from 'rxjs';
import { TableStateService } from '../../services/table-state.service';
import { RouteInfo, SidebarMenu } from './data/sidebar-menu';
import { SidebarService } from './services/sidebar.service';

@UntilDestroy()
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pae-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, NgbTooltip, NgbAccordionModule, TranslateModule],
})
export class SidebarComponent implements OnInit {
  @Input() sidebarToggle = true;
  sidebar: SidebarMenu;
  // eslint-disable-next-line id-length
  role: string = null;
  logo: string = environment.logo;
  private currentUrl: string = null;

  constructor(
    private router: Router,
    private $api: ApiService,
    private tableStateService: TableStateService,
    private sidebarService: SidebarService
  ) {
    this.setRouterEvents();
  }

  ngOnInit(): void {
    this.getRole();
  }

  isCurrentTypeUrl(type: string): boolean {
    return !!(this.currentUrl && this.searchTerm(type, this.currentUrl));
  }

  isVisible(menuItem: RouteInfo): boolean {
    return menuItem.role.includes(this.role);
  }

  onMenuClick(index: number): void {
    this.sidebar.routes[index].collapsed = !this.sidebar.routes[index].collapsed;
    this.sidebarService.setSidebar(this.sidebar);
  }

  onPathClick(): void {
    this.tableStateService.clear();
  }

  private setRouterEvents(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          if (event && event.urlAfterRedirects) {
            if (
              event.urlAfterRedirects === '/' ||
              event.urlAfterRedirects === '/dashboard'
            ) {
              this.currentUrl = '/dashboard/';
            } else {
              this.currentUrl = event.urlAfterRedirects;
            }
          }
        })
      )
      .subscribe();
  }

  private setSidebar(): void {
    const sidebar = this.sidebarService.getSidebar();
    sidebar.routes.forEach((route: RouteInfo) => {
      if (this.role === ROLE.MANAGER || this.role === ROLE.EMPLOYEE) {
        route.collapsed = false;
      }
      route.routes.forEach((routeChildren: RouteInfo) => {
        if (this.isCurrentTypeUrl(routeChildren.type)) {
          route.collapsed = false;
        }
      });
    });
    this.sidebar = sidebar;
  }

  private getRole(): void {
    this.$api.role$
      .pipe(
        untilDestroyed(this),
        filter((role: ROLE) => !!role),
        tap((role: ROLE) => {
          this.role = role;
          this.setSidebar();
        })
      )
      .subscribe();
  }

  private searchTerm(term: string, url: string): boolean {
    // Escapamos la palabra para usarla en una expresión regular
    const termScaped = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Creamos la expresión regular con la palabra entre barras
    const regex = new RegExp(`/${termScaped}/`, 'i');

    // Comprobamos si la palabra está entre barras en la URL
    return regex.test(url);
  }
}
