// Core modules
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Register locale ES
registerLocaleData(localeEs, 'es-ES', localeEsExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
