import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';

import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInMemoryScrolling, provideRouter } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { HttpLoaderFactory } from './app/app.module';
import { AppRoutes } from './app/app.routing';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLanguage,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      CoreModule,
      ClipboardModule
    ),
    { provide: DecimalPipe },
    provideNgxMask(),
    provideAnimations(),
    provideRouter(
      AppRoutes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
  ],
});
