/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/typedef */
import { Routes } from '@angular/router';
import { environment } from '@env';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: environment.machineryGUI ? 'tracking' : 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/admin-layout/admin-layout.routing').then(
            (mod) => mod.AdminLayoutRoutingModule
          ),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'session',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'signin' },
      {
        path: '',
        loadChildren: () =>
          import('./modules/session/session.routing').then(
            (mod) => mod.SessionRoutingModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: environment.machineryGUI ? 'tracking' : 'dashboard',
  },
];
